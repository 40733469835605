import React, { useState, useEffect } from "react"
import axios from "axios"
import { useSelector } from "react-redux"
import Spinners from "components/Common/Spinner"
import { useNavigate } from "react-router-dom"
import { Card, CardBody, Container } from "reactstrap"

const Home = () => {
  const [Loading, setLoading] = useState(false);
  const [currentAcadId, setCurrentAcadId] = useState(null)
  const [NewURL, setNewURL] = useState(null)
  const live_Url = "https://marcopolo.prisms.in"
  const sid = localStorage.getItem("sid")?.replace(/"/g, "")
  const uid = localStorage.getItem("user_id")
  const [schoolName, setSchoolName] = useState()
  const navigate = useNavigate()

  const getSchoolName = async () => {
    const data = {
      fun_name: "getSchoolURL",
      sid: localStorage.getItem("sid").replace(/"/g, ""),
    }
    await axios({
      method: "POST",
      url: "https://topschool.prisms.in/rest/index.php/user_list.json",
      data,
    }).then(response => {
      // console.log(response.data)
      setSchoolName(response.data)
    })
  }

  useEffect(() => {
    const getAcadId = async () => {
      try {
        const response = await axios.post(
          `${live_Url}/api/tscommon/getCurrentAcad`,
          { sid }
        )
        setCurrentAcadId(response.data[0]?.acadid)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    getAcadId()
  }, [sid])

  useEffect(() => {
    getSchoolName()
    if (currentAcadId && uid && schoolName) {
      const m = btoa(`${currentAcadId}-${uid}`)
      const New_Url = `https://${schoolName}.prisms.in/cp?db=${m}`
      console.log(New_Url)
      setNewURL(New_Url)
    }
  }, [currentAcadId, uid])

  useEffect(() => {
    if (NewURL) {
      window.history.pushState(null, "", "/dashboard")
      const timer = setTimeout(() => {
        window.location.href = NewURL
      }, 200)

      const handlePopState = () => {
        navigate("/dashboard")
      }

      window.addEventListener("popstate", handlePopState)

      return () => {
        clearTimeout(timer)
        window.removeEventListener("popstate", handlePopState)
      }
    }
  }, [NewURL, navigate])

  if (Loading || currentAcadId === null || !NewURL) {
    return (
      <div className="page-content">
        <Spinners />
      </div>
    )
  }

  return (
    <div className="page-content">
      <Container>
        <Card>
          <CardBody>
            <h4 className="fs-5 text-center text-dark">Redirecting...</h4>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Home
