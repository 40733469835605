import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Card, CardBody, Container } from "reactstrap"

const Home = () => {
  const navigate = useNavigate()
  const uid = localStorage.getItem("user_id") || ""
  const schoolName = localStorage.getItem("school_url")?.replace(/"/g, "") || ""

  useEffect(() => {
    if (uid && schoolName) {
      const newUrl = `https://lms.prisms.in/${schoolName}?usertype=teacher&uid=${uid}`
      window.history.pushState(null, "", "/dashboard")
      const timer = setTimeout(() => {
        window.location.href = newUrl
      }, 200)

      const handlePopState = () => navigate("/dashboard")

      window.addEventListener("popstate", handlePopState)

      return () => {
        clearTimeout(timer)
        window.removeEventListener("popstate", handlePopState)
      }
    }
  }, [uid, schoolName, navigate])

  return (
    <div className="page-content">
      <Container>
        <Card>
          <CardBody>
            <h4 className="fs-5 text-center text-dark">Redirecting...</h4>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Home
