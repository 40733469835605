import Spinners from "components/Common/Spinner"
import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

const ExternalLinks = () => {
  const location = useLocation()
  const { webLink } = location.state
  const [Loading, setLoading] = useState(true)
  const [fullLink, setFullLink] = useState("")

  const handleIframeLoad = () => {
    setLoading(false)
  }

  useEffect(() => {
    const isExternalLink = /^https?:\/\//.test(webLink)

    const newStr = webLink.replace(/{!school_url}|{!uid}|{!sid}/gi, x => {
      switch (x) {
        case "{!schoolurl}":
          return localStorage.getItem("school_url")
        case "{!school_url}":
          return localStorage.getItem("school_url")
        case "{!uid}":
          return localStorage.getItem("user_id")
        case "{!sid}":
          return localStorage.getItem("sid")
        default:
          return x
      }
    })

    if (!isExternalLink) {
      setFullLink(`https://${newStr}`)
    } else {
      setFullLink(newStr)
    }
  }, [webLink])

  return (
    <div
      className="px-0"
      style={{ paddingTop: "8vh", height: "100vh", paddingBottom: "55px" }}
    >
      <div style={{ display: Loading ? "block" : "none" }}>
        <Spinners />
      </div>
      {fullLink && fullLink !== "" && (
        <iframe
          src={fullLink}
          style={{
            display: Loading ? "none" : "block",
            width: "100%",
            height: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          title="Iframe Example"
          onLoad={handleIframeLoad}
        ></iframe>
      )}
    </div>
  )
}

export default ExternalLinks
